import React from "react";
import { Helmet } from "react-helmet";
import RodinneSkelet from "../components/rodinneSkelet";
import { Link } from "gatsby";
import Layout from "../components/layout";


const Rodinne = () => {




  const photoList = [
    {
      id: 1,
      style: "col-4",
      preview: "rodinne/n/1.webp",
      full: "rodinne/f/1.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 2,
      style: "col-4",
      preview: "rodinne/n/2.webp",
      full: "rodinne/f/2.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 3,
      style: "col-4",
      preview: "rodinne/n/3.webp",
      full: "rodinne/f/3.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 4,
      style: "col-12",
      preview: "rodinne/n/4.webp",
      full: "rodinne/f/4.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 5,
      style: "col-12",
      preview: "rodinne/n/5.webp",
      full: "rodinne/f/5.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 6,
      style: "col-12",
      preview: "rodinne/n/6.webp",
      full: "rodinne/f/6.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 7,
      style: "col-12",
      preview: "rodinne/n/7.webp",
      full: "rodinne/f/7.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 8,
      style: "col-12",
      preview: "rodinne/n/8.webp",
      full: "rodinne/f/8.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 9,
      style: "col-12",
      preview: "rodinne/n/9.webp",
      full: "rodinne/f/9.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 10,
      style: "col-12",
      preview: "rodinne/n/10.webp",
      full: "rodinne/f/10.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 11,
      style: "col-12",
      preview: "rodinne/n/11.webp",
      full: "rodinne/f/11.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 12,
      style: "col-12",
      preview: "rodinne/n/12.webp",
      full: "rodinne/f/12.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 13,
      style: "col-12",
      preview: "rodinne/n/13.webp",
      full: "rodinne/f/13.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 14,
      style: "col-12",
      preview: "rodinne/n/14.webp",
      full: "rodinne/f/14.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 15,
      style: "col-4",
      preview: "rodinne/n/15.webp",
      full: "rodinne/f/15.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 16,
      style: "col-4",
      preview: "rodinne/n/16.webp",
      full: "rodinne/f/16.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 17,
      style: "col-4",
      preview: "rodinne/n/17.webp",
      full: "rodinne/f/17.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 18,
      style: "col-12",
      preview: "rodinne/n/18.webp",
      full: "rodinne/f/18.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 19,
      style: "col-12",
      preview: "rodinne/n/19.webp",
      full: "rodinne/f/19.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 20,
      style: "col-12",
      preview: "rodinne/n/20.webp",
      full: "rodinne/f/20.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 21,
      style: "col-12",
      preview: "rodinne/n/21.webp",
      full: "rodinne/f/21.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 22,
      style: "col-12",
      preview: "rodinne/n/22.webp",
      full: "rodinne/f/22.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 23,
      style: "col-12",
      preview: "rodinne/n/23.webp",
      full: "rodinne/f/23.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 24,
      style: "col-12",
      preview: "rodinne/n/24.webp",
      full: "rodinne/f/24.jpg",
      alt: "porfolio fotka rodinného focení",
    },
    {
      id: 25,
      style: "col-12",
      preview: "rodinne/n/25.webp",
      full: "rodinne/f/25.jpg",
      alt: "porfolio fotka rodinného focení",
    }
  ];


  const helmetElement = (
    <Helmet>
      <title>Rodinné focení | Martin Petrovický | Foto a video</title>
      <meta
        name="description"
        content="Ukázka rodinných fotografií."
      />
      <meta
        name="keywords"
        content="Ples, Maturita, fotograf, kameraman, maturitní video, maturitní fotograf, plesový kameraman, maturitní kameraman, prom"
      />
      <meta name="author" content="Martin Petrovický" />
    </Helmet>
  );


  const togglePack = (packId) => {
    var paragraph = document.querySelector(`.pp${packId}`);
    paragraph.classList.toggle("expand-pack");
    var icon = document.querySelector(`.icon${packId}`);
    icon.classList.toggle("icon-expand");
  };

  return (
    <Layout>
      <main>
        {helmetElement}
        <div className="container">
          <div className="text-center h1 mt-2 mt-md-5 mb-4 ">Rodinné focení</div>

          <div className="pb-2 pb-md-5">
            <RodinneSkelet photoList={photoList} />
          </div>

          <div className="row py-5 text-center">
            <div className="col-12 col-lg-6 text-left">
              <h2 className="mb-3">JAKÉ FOTKY OBDRŽÍTE?</h2>
              <ul className="list-dots">
                <li>fotit můžeme kdekoliv, kde je vám to příjemné</li>
                <li className="my-2">pro focení můžeme využít i ateliér ( Chlumec nad Cidlinou )</li>
                <li>V rámci balíčku vybíráte alespoň ze 200 fotografií</li>
                <li className="my-2">Obdžíte 10 upravených fotografií v digitální podobě dle vlastního výběru</li>
                <li>Každá další fotografie navíc 200 kč</li>
                <li className="my-2">Do 2 dnů obdžíte online galerii s náhledem fotografií</li>
              </ul>
            </div>

            <div className="col-12 col-lg-6">
              <h2 className="mb-3">CENA</h2>
              <p className="my-0 my-md-4" style={{ fontSize: '140%' }}>2 500 Kč</p>
              <Link to="/kontakt">
                <div className="btn btn-svatby rounded-0 border border-dark border-end-0 border-start-0 mt-3 mb-5">
                  Napište mi
                </div>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Rodinne;
