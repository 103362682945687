import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import ImageFadeIn from "react-image-fade-in";

// import foto from '../../static/images/rodinne '

const RodinneSkelet = (props) => {

    const [isVisible, setIsVisible] = useState(false);
    const [slide, setSlide] = useState(0);

    const showSlide = (slide) => {
        setIsVisible(!isVisible);
        setSlide(slide);
    };

    const image = (props) => (
        <ImageFadeIn
            opacityTransition={1.5}
            className={props.style + " py-1 py-lg-3 px-1 px-lg-3 h-auto"}
            src={require(`../../static/images/${props.preview}`).default}
            alt={props.alt}
            onClick={() => showSlide(props.id)}
        />
    );

    return (

        <div>
            <FsLightbox
                toggler={isVisible}
                slide={slide}
                sources={props.photoList.map((item) => require(`../../static/images/${item.full}`).default)}
            />

            <div>
                {/* prvni vodorvna */}
                <div className="row">
                    {image(props.photoList[0])}
                    {image(props.photoList[1])}
                    {image(props.photoList[2])}
                </div>
                {/* prvni řada sloupců  */}
                <div className="row">
                    {/* první sloupec  */}
                    <div className="col-4 p-0">
                        {image(props.photoList[3])}
                        {image(props.photoList[4])}
                        {image(props.photoList[5])}
                        {image(props.photoList[6])}
                    </div>
                    {/* druhy sloupec */}
                    <div className="col-5 p-0">
                        {image(props.photoList[7])}
                        {image(props.photoList[8])}
                        {image(props.photoList[9])}
                    </div>
                    {/* treti sloupec */}
                    <div className="col-3 p-0">
                        {image(props.photoList[10])}
                        {image(props.photoList[11])}
                        {image(props.photoList[12])}
                        {image(props.photoList[13])}
                    </div>
                </div>
                {/* druha vodorvna */}
                {props.photoList.length >= 18 &&

                    <>
                        <div className="row">
                            {image(props.photoList[14])}
                            {image(props.photoList[15])}
                            {image(props.photoList[16])}
                        </div>
                        <div className="row">
                            {/* první sloupec  */}
                            <div className="col-4 p-0">
                                {image(props.photoList[17])}
                                {image(props.photoList[18])}
                                {image(props.photoList[23])}
                            </div>
                            {/* druhy sloupec */}
                            <div className="col-5 p-0">
                                {image(props.photoList[19])}
                                {image(props.photoList[20])}
                            </div>
                            {/* treti sloupec */}
                            <div className="col-3 p-0">
                                {image(props.photoList[21])}
                                {image(props.photoList[22])}
                                {image(props.photoList[24])}
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
};

export default RodinneSkelet;
